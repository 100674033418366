<template>
  <section class="ls-container">
    <BreadCrump :breadcrumbList="breadcrumbList" />

    <div class="search-wrap main-section">
      <div class="use-title">使用指南</div>
      <div v-for="(item, i) in useHint" :key="i" class="use-hint">
        {{ item }}
      </div>
      <div class="search-keyword">
        <input
          v-model="keyword"
          type="text"
          placeholder="区块高度、交易哈希地址"
          maxlength="250"
        />
        <span @click="searchFunc" class="ls-icons icon-search"></span>
      </div>
    </div>
  </section>
</template>
<script>
import BreadCrump from "@/components/common/BreadCrump.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    BreadCrump,
  },
  data() {
    return {
      breadcrumbList: [{ menuName: "综合查询" }],
      useHint: [
        "1. 您可以通过输入区块高度、交易哈希地址进行搜索查询；",
        "2. 以上数据您都可以通过在与零数开放许可链的对接中，通过您的业务活动产生的上链记录中获得；",
        "3. 所有您业务活动获得的区块链记录数据，以及在本页面查询获得的数据信息，都来源于零数开放许可链的官方区块链浏览器中；",
        "4. 我们已经着手在对综合查询功能进行迭代和优化，会尽快与您见面。",
      ],
      keyword: undefined,
    };
  },
  methods: {
    ...mapMutations(["setQueryInfo"]),
    searchFunc() {
      if (!this.keyword) {
        return;
      }
      this.setQueryInfo({
        integratedKeyword: this.keyword,
      });
      this.$router.push({ name: "IntegratedQueryResult" });
    },
  },
  created() {
    this.setQueryInfo({
      integratedKeyword: "",
    });
  },
};
</script>
<style lang="scss" scoped>
.search-wrap {
  padding: 36px 51px 0;
  min-height: 705px;
  background-color: #fff;
  margin-top: 16px;
  .use-title {
    font-size: 14px;
    line-height: 20px;
    color: #3d3d3d;
    margin-bottom: 34px;
  }
  .use-hint {
    font-size: 14px;
    line-height: 20px;
    color: #3d3d3d;
    margin-bottom: 24px;
  }

  .search-keyword {
    position: relative;
    border: 1px solid #dadada;
    width: 640px;
    height: 52px;
    margin: 36px 0 0 10px;
    input {
      width: 100%;
      height: 100%;
      padding: 0 51px 0 29px;
      border: 0;
      font-size: 14px;
      color: #818181;
    }
    .icon-search {
      @extend %vertical-center;
      right: 16px;
      cursor: pointer;
    }
  }
}
</style>
